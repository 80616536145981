.linkButton {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: #007AFF;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}