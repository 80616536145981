.footer {
  position: relative;
  z-index: 100;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  padding: 24px;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  border-radius: 16px;

  @media (max-width: 800px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .left {
    @media (max-width: 800px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .logo {
    max-width: 120px;

    @media (max-width: 800px) {
      margin-bottom: 16px;
    }

    img {
      width: 100%;
    }
  }

  .description {
    font-size: 16px;
    color: #8e8e93;

    @media (max-width: 800px) {
      max-width: 360px;
      margin-bottom: 24px;
      text-align: center;
    }
  }

  .email {
    color: inherit;
    text-decoration: none;
    margin-bottom: 10px;

    @media (max-width: 800px) {
      margin-bottom: 38px;
    }
  }

  .socials {
    display: flex;
    justify-content: space-between;
    margin: 8px 0 16px;
  }

  .right {
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @media (max-width: 800px) {
      justify-self: center;
      align-items: initial;
      flex-direction: row;
      justify-content: space-between;
    }

    @media (max-width: 550px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .link {
    margin-bottom: 24px;
    font-size: 14px;
    color: #007aff;
    text-decoration: none;

    @media (max-width: 800px) {
      margin: 0 32px;
    }

    @media (max-width: 550px) {
      margin-bottom: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .copyright {
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
    opacity: 0.5;
  }

  .contact {
    font-size: 14px;
    opacity: 0.8;
  }
}
