.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
  padding: 20px 0;
  background-color: transparent;
  transition: background-color 0.2s ease-in-out;

  // @media (max-width: 850px) {
  //   padding: 12px 0;
  // }

  .container {
    max-width: 1200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    @media (max-width: 1200px) {
      padding: 0 32px;
    }

    @media (max-width: 768px) {
      padding: 0 24px;
    }
  }

  .content {
    display: flex;
    align-items: center;
  }

  .logo {
    max-width: 140px;
    margin-right: 20px;
  }

  .nav {
    @media (max-width: 850px) {
      display: none;
    }
  }

  .navItem {
    display: inline-block;
    margin: 0 24px;
    font-size: 14px;
    color: #8e8e93;
    text-decoration: none;
    transition: color 0.2s ease-out;

    &:hover {
      color: #161616;
      transition: color 0.2s ease-out;
    }

    @media (max-width: 1000px) {
      margin: 0 16px;
    }
  }

  .buttons {
    display: flex;
    align-items: center;

    @media (max-width: 850px) {
      display: none;
    }
  }

  .signUp {
    display: inline-block;
    margin-right: 56px;
    font-size: 16px;
    color: #007aff;
    text-decoration: none;

    @media (max-width: 1000px) {
      margin-right: 40px;
    }
  }

  .login {
    max-width: 101px;
    text-decoration: none;
  }
}

// .scrolled {
//   background-color: #fff;
//   box-shadow: 0 16px 24px 0 rgba($color: #97C9FF, $alpha: 0.12);
//   transition: background-color 0.2s ease-in-out;
// }

.burger {
  display: none;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: #fff;
  border-radius: 12px;

  @media (max-width: 850px) {
    display: flex;
  }

  @media (max-width: 550px) {
    width: 44px;
    height: 44px;

    img {
      width: 18px;
      height: 18px;
    }
  }
}
