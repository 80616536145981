.menuHeader {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  box-shadow: 0 4px 8px 0 rgba(#97C9FF, 0.16);

  @media (min-width: 768px) {
    position: sticky;
  }

  .left{
    display: flex;
    align-items: center;
  }

  .logo {
    margin-right: 20px;
  }

  .title {
    font-size: 14px;
    font-weight: 600;
  }
}