.perfectlyFit {
  position: relative;

  @media (max-width: 830px) {
    margin-bottom: 250px;
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .title {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 3px;
    font-size: 40px;
    font-weight: 600;

    @media (max-width: 830px) {
      top: 100px;
      width: calc(50% - 24px);
      margin-bottom: 0;
      font-size: 34px;
      text-align: initial;
    }

    @media (max-width: 600px) {
      font-size: 30px;
    }

    @media (max-width: 500px) {
      top: initial;
      width: 100%;
      margin-bottom: 6px;
    }
  }

  .subtitle {
    position: relative;
    display: block;
    margin-bottom: 40px;
    font-size: 20px;
    color: #8e8e93;
    text-align: center;

    @media (max-width: 830px) {
      top: 100px;
      width: calc(50% - 24px);
      margin-bottom: 0;
      font-size: 20px;
      text-align: initial;
    }

    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (max-width: 500px) {
      top: initial;
      width: 100%;
      margin-bottom: 24px;
      font-size: 16px;
    }
  }

  .background {
    position: absolute;
    top: -40%;
    z-index: 0;

    img {
      width: 100%;
    }
  }

  .places {
    display: grid;
    grid-template-areas:
      'bars cafes markets coffee'
      'restaurants hotels hotels pizza';
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    margin-bottom: 150px;

    @media (max-width: 830px) {
      grid-template-areas:
        'restaurants bars'
        'markets cafes'
        'coffee hotels';
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 500px) {
      grid-template-areas:
        'bars cafes'
        'hotels hotels'
        'restaurants markets'
        'coffee pizza';
      gap: 16px;
    }

    .place {
      display: block;
      transition: transform 0.2s ease-out;

      &:nth-child(1) {
        grid-area: bars;
      }
      &:nth-child(2) {
        grid-area: cafes;
      }
      &:nth-child(3) {
        grid-area: markets;
      }
      &:nth-child(4) {
        grid-area: coffee;
      }
      &:nth-child(5) {
        grid-area: restaurants;
      }
      &:nth-child(6) {
        grid-area: hotels;
      }
      &:nth-child(7) {
        grid-area: pizza;

        @media (max-width: 830px) {
          display: none;
        }
        @media (max-width: 500px) {
          display: block;
        }
      }

      @media (max-width: 830px) {
        &:nth-child(5),
        &:nth-child(3),
        &:nth-child(4) {
          transform: translateY(60%);
        }
      }

      @media (max-width: 500px) {
        &:nth-child(5),
        &:nth-child(3),
        &:nth-child(4) {
          transform: none;
        }
      }

      &:hover {
        transform: scale(1.05);
        transition: transform 0.2s ease-out;
      }
    }
  }
}
