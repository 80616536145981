.mobileMenu {
  position: absolute;
  top: -100vh;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 10000;
  padding: 32px;
  background-color: #fdfdfd;
  transition: all .3s ease-out;
}

.mobileMenu.opened {
  top: 0;
  transition: all .3s ease-out;
}

.navItem {
  opacity: 0;
  transition: opacity .3s ease-out;
}


.opened .navItem {
  opacity: 1;
  transition: opacity .3s ease-out;

  &:nth-child(1){
    transition-delay: .3s;
  }
  &:nth-child(2){
    transition-delay: .4s;
  }
  &:nth-child(3){
    transition-delay: .5s;
  }
  &:nth-child(4){
    transition-delay: .6s;
  }
}

.close {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 44px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 12px;
}

.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}


.navItem {
  display: inline-block;
  margin-bottom: 44px;
  font-size: 14px;
  color: #161616;
  text-decoration: none;
  transition: color 0.2s ease-out;

  &:hover {
    color: #161616;
    transition: color 0.2s ease-out;
  }
}

.buttons {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
}

.opened .buttons {
  opacity: 1;
  transition: opacity .3s ease-out;
  transition-delay: .7s;
}

.signUp {
  display: inline-block;
  color: #007aff;
  text-decoration: none;
}

.login {
  width: 100%;
  text-decoration: none;
  margin-bottom: 38px;
}

