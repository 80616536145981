.defaultButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  background: linear-gradient(170deg, #fda14d 0%, #fd4df6 100%);
  background-position-x: 0;
  color: #ffffff;
  padding: 14px 33px;
  border-radius: 12px;
  cursor: pointer;
  border: none;
  outline: none;

  .svg {
    margin-right: 11px;
  }

  .svgStart {
    margin-right: 5px;
  }

  .svgEnd {
    margin-left: 5px;
  }

  &:hover {
    box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
    margin: -2px 0;
    border: solid 2px transparent;
    background-image: linear-gradient(
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0)
      ),
      linear-gradient(150deg, #ffffff 0%, #fda14d 0%, #fd4df6 100%, #ffffff 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    box-shadow: 2px 1000px 1px #fff inset;

    span {
      background: linear-gradient(
        150deg,
        #ffffff 0%,
        #fda14d 0%,
        #fd4df6 100%,
        #ffffff 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}

.secondaryButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 12px;
  padding: 12px 33px;
  font-size: 16px;
  color: #ffffff;
  box-shadow: 0 0 6px 0 rgba(157, 96, 212, 0.5);
  border: solid 2px transparent;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0)
    ),
    linear-gradient(150deg, #ffffff 0%, #fda14d 0%, #fd4df6 100%, #ffffff 100%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  cursor: pointer;

  span {
    background: linear-gradient(
      150deg,
      #ffffff 0%,
      #fda14d 0%,
      #fd4df6 100%,
      #ffffff 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .svg {
    margin-right: 11px;
  }
}
