.workdays {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .workdaysItem {
    display: flex;
    justify-content: space-between;
  }

  .day {
    font-weight: 500;
  }
}