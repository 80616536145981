.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('./../../../assets/images/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh;

  .label {
    margin-top: 20px;
    font-size: 13px;
    opacity: 0.7;
  }
}