.socialItem {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  box-shadow: 0 4px 8px 0 rgba($color: #97C9FF, $alpha: 0.16);
  border-radius: 8px;

  img {
    width: 20px;
    height: 20px;
  }
}