.menuPage {
  position: relative;
  min-height: 200vh;
  background-color: #edf0f6;
  background-image: url('./../../assets/images/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    @media (min-width: 768px) {
      position: relative;
      margin: 0 auto;
      max-width: 768px;
    }
  }

  .menuPdf {
    margin: 60px 0;
  }
}
