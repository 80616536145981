.landingPage {
  background-color: #edf0f6;
  overflow-x: hidden;

  .container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 48px;
    background-image: url('./../../assets/images/journey-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    @media(max-width: 1200px) {
      padding: 0 32px 48px;
    }

    @media(max-width: 768px) {
      padding: 0 24px 48px;
    }
  }
}
