.benefits {
  position: relative;
  margin-bottom: 150px;

  @media (max-width: 950px) {
    margin-top: -90px;
    margin-bottom: 220px;
  }

  @media (max-width: 600px) {
    margin-top: 0;
    margin-bottom: 100px;
  }

  .background {
    position: absolute;
    top: -60%;
    z-index: 0;

    @media (max-width: 950px) {
      top: -20%;
    }

    @media (max-width: 600px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .title {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 600;

    @media (max-width: 950px) {
      top: 130px;
      width: calc(50% - 24px);
      margin-bottom: 0;
      text-align: initial;
    }

    @media (max-width: 830px) {
      font-size: 34px;
    }

    @media (max-width: 700px) {
      font-size: 30px;
    }

    @media (max-width: 600px) {
      top: initial;
      width: 100%;
      margin-bottom: 24px;
    }
  }

  .benefitsItems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    align-items: center;
    margin-bottom: 44px;

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  .benefitsItem {
    position: relative;
    transition: transform 0.2s ease-out;

    &:nth-child(1) {
      height: 92%;
    }
    &:nth-child(2) {
      height: 97%;
    }
    &:nth-child(3) {
      height: 100%;
    }
    &:nth-child(4) {
      height: 85%;
    }

    &:hover {
      transform: translateY(-10px);
      transition: transform 0.2s ease-out;
    }

    @media (max-width: 950px) {
      &:nth-child(1) {
        height: 98%;
        top: 48%;
      }

      &:nth-child(2) {
        height: 96%;
        top: 5%;
      }

      &:nth-child(3) {
        height: 98%;
        top: 48%;
      }

      &:nth-child(4) {
        height: 92%;
      }
    }

    @media (max-width: 600px) {
      &:nth-child(n) {
        top: 0;
        height: 100%;
      }
    }
  }

  .link {
    text-decoration: none;
  }

  .allFeatures {
    margin: 0 auto;

    @media (max-width: 950px) {
      display: none;
    }
  }
}
