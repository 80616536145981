.menuFooter {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #ffffff, $alpha: 0.9);

  @media (min-width: 768px) {
    position: sticky;
  }

  .buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    padding: 16px;

    a {
      text-decoration: none;
      width: 100%;
    }
  }
}
