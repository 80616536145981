body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  color: #161616;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
}

.hidden {
  overflow: hidden;
}

:root {
  --toastify-color-progress-light: linear-gradient(170deg, #fda14d 0%, #fd4df6 100%);
}