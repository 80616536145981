.weBelievePage {
  background-color: #edf0f6;
  overflow-x: hidden;

  .container {
    position: relative;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 48px;
    background-image: url('./../../assets/images/journey-bg.png');
    background-repeat: no-repeat;
    background-position: bottom;
    background-size: contain;

    @media (max-width: 1200px) {
      padding: 0 32px 48px;
    }

    @media (max-width: 768px) {
      padding: 0 24px 48px;
    }
  }

  .title {
    font-size: 72px;
    line-height: 30px;
    font-weight: 600;

    @media (max-width: 650px) {
      font-size: 40px;
    }
  }

  .description {
    display: inline-block;
    max-width: 590px;
    margin-bottom: 80px;
    text-align: center;
    font-size: 20px;
    color: #8e8e93;

    @media (max-width: 650px) {
      font-size: 16px;
    }
  }

  .intro {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    padding: 80px 0;
    align-items: center;
    justify-content: center;
    min-height: 100vh;

    .button {
      max-width: 282px;
      font-size: 20px;
      padding: 20px;

      @media (max-width: 400px) {
        max-width: 100%;
      }
    }
  }

  .features {
    position: relative;
    margin-bottom: 150px;

    @media (max-width: 650px) {
      margin-bottom: 80px;
    }

    .background {
      position: absolute;
      top: -400px;
      z-index: 0;

      @media (max-width: 950px) {
        top: -20%;
      }

      @media (max-width: 600px) {
        display: none;
      }

      img {
        width: 100%;
      }
    }

    .content {
      position: relative;
      z-index: 1;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 24px;

      @media (max-width: 834px) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: 500px) {
        display: flex;
        flex-direction: column;
      }
    }

    .feature {
      transition: transform 0.2s ease-out;

      &:hover {
        transform: translateY(-10px);
        transition: transform 0.2s ease-out;
      }

      &:nth-child(2) {
        height: 74%;

        @media (max-width: 834px) {
          height: 78%;
        }

        @media (max-width: 500px) {
          height: 100%;
        }
      }

      &:nth-child(3) {
        height: 84%;

        @media (max-width: 834px) {
          order: 4;
          height: 96%;
          transform: translateY(-60px);
        }

        @media (max-width: 500px) {
          height: 100%;
          transform: none;
        }
      }

      &:nth-child(4) {
        height: 74%;

        @media (max-width: 834px) {
          order: 3;
          height: 79%;
        }

        @media (max-width: 500px) {
          height: 100%;
        }
      }
    }
  }

  .supportUs {
    display: flex;
    flex-direction: column;
    margin-bottom: 150px;
    align-items: center;
    text-align: center;

    @media (max-width: 650px) {
      margin-bottom: 80px;
    }

    .titleSecond {
      font-size: 40px;
    }

    .cryptoItems {
      display: flex;
      justify-content: space-between;
      gap: 24px;
      width: 100%;

      @media (max-width: 650px) {
        flex-direction: column;
      }
    }

    .cryptoItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 50%;
      padding: 24px;
      border-radius: 16px;
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      box-shadow: 0 16px 24px 0 rgba($color: #97c9ff, $alpha: 0.12);

      @media (max-width: 650px) {
        width: 100%;
      }

      .icon {
        width: 80px;
        margin-bottom: 16px;
      }

      .cryptoTitle {
        display: inline-block;
        margin-bottom: 8px;
        font-size: 20px;
        font-weight: 600;
      }

      .wallet {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        width: 100%;
      }

      input {
        width: 100%;
        margin-bottom: 0;
      }

      .copy {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        min-height: 100%;
        background-color: #fff;
        border-radius: 12px;
        cursor: pointer;
      }
    }
  }
}
