.placesItem {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  box-shadow: 0 16px 24px 0 rgba($color: #97c9ff, $alpha: 0.12);
  border-radius: 16px;

  .image {
    max-width: 100%;
    margin-bottom: 16px;
    border-radius: 12px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .text {
    font-size: 20px;
    font-weight: 600;
  }

  @media (max-width: 830px) {
    height: 244px;
    .image {
      height: 150px;
      object-fit: contain;
      img {
        // width: auto;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 500px) {
    height: 170px;
    padding: 16px;
    .image {
      height: 98px;
    }

    .text {
      font-size: 16px;
    }
  }
}
