.comingSoonModal {
  position: absolute;
  z-index: 99;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  width: 550px;
  padding: 24px;
  background-color: #fff;
  border-radius: 14px;
  outline: none;
  box-shadow: 0 16px 24px 0 rgba($color: #97c9ff, $alpha: 0.12);

  @media(max-width: 600px){
    width: 90%;
  }

  .title {
    display: inline-block;
    margin-bottom: 12px;
    font-size: 34px;
    font-weight: 600;
  }

  .close {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  input {
    width: 100%;
  }

  button {
    width: 100%;
  }

  .thanks {
    display: inline-block;
    margin-bottom: 10px;
    text-align: center;
    font-size: 16px;
    color: #8e8e93;
  }
}
