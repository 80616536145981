.pricingItem {
  max-height: 384px;
  padding: 27px 24px 24px;
  background-color: rgba($color: #ffffff, $alpha: 0.8);
  border-radius: 16px;
  box-shadow: 0 16px 16px 0 rgba($color: #97c9ff, $alpha: 0.12);

  @media (max-width: 1000px) {
    max-height: 500px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-bottom: 14px;
    border-bottom: 1px solid #e5e5ea;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: center;
    }

    @media (max-width: 640px) {
      flex-direction: row;
    }

    .name {
      font-size: 30px;
      font-weight: 600;
      @media (max-width: 1000px) {
        margin-bottom: 16px;
        font-size: 24px;
      }

      @media (max-width: 640px) {
        margin-bottom: 0;
      }
    }

    .plan {
      display: flex;
      align-items: flex-start;

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }

      @media (max-width: 640px) {
        flex-direction: row;
        align-items: flex-start;
      }

      .cost {
        font-size: 34px;
        font-weight: 600;
        margin-right: 3px;
      }

      .period {
        font-size: 14px;
        color: #8e8e93;
        transform: translateY(14px);

        @media (max-width: 1000px) {
          transform: none;
        }

        @media (max-width: 640px) {
          transform: translateY(14px);
        }
      }
    }
  }

  .features {
    .featuresItem {
      display: flex;
      align-items: center;
      margin: 32px 0;
      color: #8e8e93;

      @media (max-width: 1000px) {
        font-size: 14px;
      }
    }

    .check {
      display: inline-block;
      margin-right: 24px;

      path {
        stroke: #8e8e93;
      }
    }
  }

  .button {
    padding: 20px 0;
    font-size: 20px;
  }
}

.pricingItemHighlighted {
  max-height: 446px;
  background: linear-gradient(130deg, #fda14d 0%, #fd4df6 100%);

  @media (max-width: 1000px) {
    max-height: 550px;
  }

  .header {
    padding-bottom: 32px;
    margin-bottom: 48px;
    color: #fff;
    border-bottom-color: #fff;

    .plan .period {
      color: #fff;
    }
  }

  .features {
    .featuresItem {
      color: #fff;
    }

    .check {
      path {
        stroke: #ffffff;
      }
    }
  }

  .button {
    background: #fff;
    margin-top: 48px;
  }
}
