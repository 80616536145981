.startJourney {
  position: relative;
  z-index: 1;
  margin-bottom: 150px;

  .background {
    position: absolute;
    z-index: 0;

    @media (max-width: 600px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;

    @media (max-width: 550px) {
      flex-direction: column;
    }
  }

  .image {
    max-width: 383px;
    margin-right: 48px;

    @media (max-width: 800px) {
      max-width: 50%;
      width: 100%;
    }

    @media (max-width: 550px) {
      order: 2;
      max-width: 100%;

      img {
        margin-bottom: 24px;
      }
    }

    .img {
      width: 100%;
    }
  }

  .info {
    @media (max-width: 800px) {
      width: 50%;
    }

    @media (max-width: 550px) {
      order: 1;
      width: 100%;
    }
  }

  .title {
    display: inline-block;
    margin-bottom: 5px;
    font-size: 40px;
    font-weight: 600;

    @media (max-width: 800px) {
      margin-top: 0;
    }
  }

  .description {
    margin-bottom: 72px;
    font-size: 20px;
    color: #8e8e93;

    @media (max-width: 800px) {
      margin-bottom: 60px;
    }

    @media (max-width: 720px) {
      margin-bottom: 30px;
      font-size: 16px;
    }
  }

  .button {
    max-width: 225px;
    font-size: 20px;
    padding: 20px 0;
    margin-bottom: 20px;

    &:hover {
      margin: -2px 0 18px;

      svg path {
        stroke: #fd4df6;
      }
    }

    @media (max-width: 550px) {
      display: none;
    }

    img {
      margin-right: 0;
    }
  }

  .link {
    @media (max-width: 550px) {
      display: none;
    }
  }

  a {
    text-decoration: none;
  }

  .buttonMobile {
    display: none;
    font-size: 20px;
    padding: 20px 0;
    margin-bottom: 20px;
    text-decoration: none;

    img {
      margin: 0;
    }

    @media (max-width: 550px) {
      display: flex;
      align-items: center;
    }
  }

  .linkMobile {
    display: none;

    @media (max-width: 550px) {
      display: block;
    }
  }
}
