.subtitle {
  display: block;
  font-size: 12px;
  margin-bottom: 8px;
}

.title {
  display: block;
  font-size: 24px;
  font-weight: 600;
  max-width: 80%;
  margin-bottom: 6px;
}

.rateUs {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 24px;

  .rateUsItem {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: #f3f5f7;
    box-shadow: 0 8px 16px 0 rgba($color: #97c9ff, $alpha: 0.12);
    border-radius: 12px;
    cursor: pointer;

    .top {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 36px;
    }

    img {
      width: 64px;
      height: 64px;
    }

    button {
      border-radius: 0 0 12px 12px;
    }
  }
}

.titleSad {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 600;

  img {
    width: 44px;
    height: 44px;
    margin-right: 16px;
  }
}

.tellMe {
  height: 160px;
  padding: 16px;
  font-size: 16px;
  font-family: inherit;
  color: #8e8e93;
  resize: none;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #f3f5f7;
  margin-bottom: 24px;
}

.feedback {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  .feedbackLabel {
    display: inline-block;
    max-width: 70%;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .switchContainer {
    position: relative;
    display: inline-block;
    width: 48px;
    height: 24px;
  }

  .switchContainer input[type='checkbox'] {
    display: none;
  }

  .switchContainer .switch {
    display: block;
    position: absolute;
    width: 48px;
    height: 24px;
    cursor: pointer;
    background-image: linear-gradient(120deg, #fff 100%, #fff 100%);
    border-radius: 12px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    transition: background-image 0.2s ease;
  }

  .border {
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: -1px;
    right: -1px;
    background: linear-gradient(120deg, #fda14d 20%, #fd4df6 100%);
    border-radius: 12px;
    z-index: 0;
  }

  .switchContainer .switch::before {
    position: absolute;
    content: '';
    left: 2px;
    top: 2px;
    width: 21px;
    height: 21px;
    background-image: linear-gradient(120deg, #fda14d 20%, #fd4df6 100%);
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .switchContainer input[type='checkbox']:checked + .switch::before {
    transform: translateX(23px);
    background-image: linear-gradient(120deg, #fff 100%, #fff 100%);
  }

  .switchContainer input[type='checkbox']:checked + .switch {
    background-image: linear-gradient(120deg, #fda14d 20%, #fd4df6 100%);
  }
}

.label {
  font-weight: 600;
  display: block;
  font-size: 13px;
  margin-bottom: 8px;
}

.rating {
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.devider {
  margin-bottom: 24px;
}

.reviews {
  display: flex;
  flex-direction: column;
  gap: 24px;

  .reviewLink {
    text-decoration: none;
  }
}