.intro {
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100vh;

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
  }

  .content {
    display: flex;
    flex-direction: column;
    padding: 80px 0;

    @media (max-width: 600px) {
      margin-top: 134px;
    }
  }

  .title {
    display: inline-block;
    max-width: 612px;
    margin: 0;
    font-size: 72px;
    font-weight: 600;
    line-height: 80px;
    margin-bottom: 5px;

    @media (max-width: 900px) {
      max-width: 608px;
    }

    @media (max-width: 600px) {
      max-width: 327px;
      font-size: 34px;
      line-height: initial;
    }

    @media (max-width: 450px) {
      max-width: 100%;
    }
  }

  .subtitle {
    display: inline-block;
    max-width: 382px;
    margin-bottom: 80px;
    font-size: 20px;
    color: #8e8e93;

    @media (max-width: 600px) {
      margin-bottom: 40px;
    }
  }

  .tryFree {
    max-width: 282px;
    padding: 20px 32px;
    font-size: 20px;

    img {
      margin-right: 0;
    }

    svg {
      margin-right: 0;
    }

    &:hover {
      svg path{
        stroke: #fd4df6;
      }
    }

    @media (max-width: 600px) {
      max-width: 100%;
    }
  }

  .image {
    max-width: 608px;
    transform: translateX(40px);

    @media (max-width: 900px) {
      min-width: 350px;
      transform: translate(-80px, 100px);
    }

    @media (max-width: 600px) {
      transform: none;
      transform: translateX(20px);
      min-width: initial;
      width: 100%;
    }

    img {
      width: 100%;
    }
  }

  .example {
    margin-top: 20px;
    text-decoration: none;
  }
}
