.features {
  position: relative;

  @media (max-width: 830px) {
    margin-top: -150px;
  }

  .content {
    position: relative;
    z-index: 3;
  }

  .background {
    position: absolute;
    z-index: 0;
    top: -32%;

    img {
      width: 100%;
    }
  }

  .title {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 3px;
    font-size: 40px;
    font-weight: 600;

    @media (max-width: 830px) {
      top: 200px;
      width: calc(50% - 24px);
      margin-bottom: 0;
      font-size: 34px;
      text-align: initial;
    }

    @media (max-width: 600px) {
      font-size: 30px;
    }

    @media (max-width: 500px) {
      top: initial;
      width: 100%;
      margin-bottom: 6px;
    }
  }

  .subtitle {
    position: relative;
    display: block;
    font-size: 20px;
    color: #8e8e93;
    text-align: center;

    @media (max-width: 830px) {
      top: 200px;
      width: calc(50% - 24px);
      margin-bottom: 0;
      font-size: 20px;
      text-align: initial;
    }

    @media (max-width: 600px) {
      font-size: 16px;
    }

    @media (max-width: 500px) {
      top: initial;
      width: 100%;
      margin-bottom: 24px;
      font-size: 16px;
    }
  }

  .featureItems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    align-items: end;
    margin-bottom: 150px;
    margin-top: -20px;

    @media (max-width: 830px) {
      grid-template-columns: repeat(2, 1fr);
      margin-top: 0;
      align-items: center;
    }

    @media (max-width: 500px) {
      display: flex;
      flex-direction: column;
    }
  }

  .feature {
    position: relative;
    transition: transform 0.2s ease-out;

    @media (max-width: 500px) {
      width: 100%;
    }

    &:nth-child(1) {
      height: 75%;
      @media (max-width: 830px) {
        grid-column-start: 2;
      }

      @media (max-width: 500px) {
        grid-row-start: 1;
      }
    }

    &:nth-child(2) {
      height: 92%;
      top: 39%;
    }

    &:nth-child(3) {
      top: 26%;
    }

    &:nth-child(4) {
      height: 92%;
      top: 23%;
    }

    &:nth-child(5) {
    }

    &:nth-child(6) {
      height: 70%;
    }

    &:nth-child(7) {
      height: 75%;
      top: -5%;
    }

    &:nth-child(8) {
      height: 82%;
    }

    @media (max-width: 830px) {
      &:nth-child(n) {
        height: 100%;
        top: initial;
      }
      // &:nth-child(1) {
      //   height: 100%;
      // }
    }

    &:hover {
      transform: translateY(-10px);
      transition: transform 0.2s ease-out;
    }
  }
}
