.pricing {
  position: relative;
  z-index: 1;
  margin-bottom: 150px;

  .background {
    position: absolute;
    z-index: 0;
    top: -60%;

    @media (max-width: 600px) {
      display: none;
    }

    img {
      width: 100%;
    }
  }

  .content {
    position: relative;
    z-index: 1;
  }

  .title {
    position: relative;
    display: block;
    text-align: center;
    margin-bottom: 40px;
    font-size: 40px;
    font-weight: 600;
  }

  .pricingItems {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    align-items: center;

    @media (max-width: 640px) {
      grid-template-columns: 1fr;
    }
  }
}
