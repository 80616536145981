.input {
  padding: 16px;
  font-size: 16px;
  font-family: inherit;
  color: #8e8e93;
  border: none;
  outline: none;
  border-radius: 12px;
  background-color: #f3f5f7;
  margin-bottom: 24px;
}