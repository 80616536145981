.featuresItem {
  display: flex;
  flex-direction: column;
  padding: 24px;
  background-color: rgba($color: #ffffff, $alpha: 0.9);
  box-shadow: 0 16px 24px 0 rgba($color: #97c9ff, $alpha: 0.12);
  border-radius: 16px;

  @media (max-width: 500px) {
    padding: 16px;
  }

  .image {
    display: inline-block;
    width: 80px;
    height: 80px;
    margin-bottom: 24px;
  }

  .title {
    margin-bottom: 8px;
    font-size: 20px;
    font-weight: 600;

    @media (max-width: 500px) {
      font-size: 16px;
    }
  }

  .description {
    color: #8e8e93;
  }

  .link {
    display: inline-block;
    margin-top: 8px;
    text-decoration: none;
    color: #007aff;
  }
}
