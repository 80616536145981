.detailsPage {
  position: relative;
  min-height: 100vh;
  padding: 16px;
  background-color: #edf0f6;
  background-image: url('./../../assets/images/bg.png');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    background-color: #ffffff;
    width: 100%;
    padding: 24px;
    border-radius: 16px;
    margin-bottom: 120px;

    @media (min-width: 768px) {
      position: relative;
      margin: 0 auto;
      max-width: 768px;
      margin-bottom: 100px;
    }
  }

  .detailsFooter {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: #ffffff;

    @media (min-width: 768px) {
      margin: 0 auto;
      max-width: 768px;
      border-radius: 16px 16px 0 0 ;
    }

    a {
      text-decoration: none;
    }
  }

  .header {
    max-width: 100%;
    box-shadow: 0 16px 24px 0 rgba($color: #97c9ff, $alpha: 0.12);
    margin-bottom: 24px;

    img {
      display: block;
      width: 100%;
    }
  }

  .info {
    display: flex;
    flex-direction: column;
  }

  .subtitle {
    display: block;
    font-size: 12px;
    margin-bottom: 8px;
  }

  .title {
    display: block;
    font-size: 24px;
    font-weight: 600;
    max-width: 80%;
    margin-bottom: 6px;
  }

  .dropWorkdays {
    display: inline-block;
    margin-left: 10px;
    width: 12px;
  }

  .phoneLink {
    text-decoration: none;
    color: #007aff;
  }

  .infoItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 0;

    &:last-child {
      margin-bottom: 24px;
    }

    .infoItemContent {
      display: flex;
      align-items: center;
    }

    .infoItemContentIcon {
      margin-right: 8px;
    }
  }

  .description {
    margin-bottom: 24px;
  }

  .socialItems {
    display: flex;
    margin: 8px 0 24px;
    gap: 12px;
  }
}
